<template>
  <div class="dashboard workout-detail">
    <div class="workout-detail-block">
      <div class="workout-detail-top-wrapper">
        <div class="container">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-start align-items-center">
              <button type="button" class="heading-back">
                <chevronLeft/>
              </button>
              <p class="workout-detail-top-title d-none d-lg-block">Aiden Hogan</p>
            </div>
            <div class="d-flex justify-content-end align-items-center">
              <button type="button" class="workout-detail-top-btn">
                <bookmark/>
              </button>
              <!--<button type="button" class="workout-detail-top-btn">
                <shareIcon/>
              </button>-->
            </div>
          </div>
        </div>
      </div>
      <div class="workout-detail-content-wrapper">
        <div class="container">
          <div class="workout-detail-content">
            <div class="row">
              <div class="col-12 col-lg-7">
                <div class="workout-detail-image">
                  <img src="https://fakeimg.pl/1014x840/" alt="">
                  <div class="workout-detail-image-content">
                    <div class="container">
                      <div class="d-flex justify-content-start align-items-end">
                        <div class="d-lg-none">
                          <p class="workout-detail-subtitle">Treinador</p>
                          <p class="workout-detail-title">Aiden Hogan</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-5">
                <div class="workout-detail-text-wrapper">
                  <div class="container">
                    <p class="workout-detail-text-title">Sobre</p>
                    <div class="workout-detail-text-description-wrapper">
                      <div class="workout-detail-text-description">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie</p>
                      </div>
                      <div class="workout-detail-text-description-btn-wrapper d-flex justify-content-center align-items-center">
                        <button type="button" class="workout-detail-text-description-btn d-flex justify-content-center align-items-center" @click="toggleTextHeight">
                          <p>Ler Mais</p>
                          <span class="workout-detail-text-description-btn-icon">
                          <chevronDown/>
                        </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="workout-detail-calendar-wrapper section-padding">
        <div class="container">
          <div class="workout-detail-calendar-heading">
            <p class="section-title">Agenda</p>
          </div>
          <div class="workout-detail-calendar-row row">
            <div class="col-12 col-xxl-4 workout-detail-calendar-column">
              <div class="workout-calendar-wrapper">
                <v-calendar :attributes="calendarAttributes"></v-calendar>
              </div>
            </div>
            <div class="col-12 col-xxl-8 workout-detail-calendar-column">
                <div class="workout-hours-wrapper d-flex justify-content-start align-items-start">
                  <div class="workout-hours-item">
                    <label>
                      <input type="radio" name="workout-hours">
                      <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                        <span>14:00</span>
                      </div>
                    </label>
                  </div>
                  <div class="workout-hours-item">
                    <label>
                      <input type="radio" name="workout-hours">
                      <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                        <span>15:00</span>
                      </div>
                    </label>
                  </div>
                  <div class="workout-hours-item">
                    <label>
                      <input type="radio" name="workout-hours">
                      <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                        <span>16:00</span>
                      </div>
                    </label>
                  </div>
                  <div class="workout-hours-item">
                    <label>
                      <input type="radio" name="workout-hours">
                      <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                        <span>17:00</span>
                      </div>
                    </label>
                  </div>
                  <div class="workout-hours-item">
                    <label>
                      <input type="radio" name="workout-hours">
                      <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                        <span>18:00</span>
                      </div>
                    </label>
                  </div>
                  <div class="workout-hours-item">
                    <label>
                      <input type="radio" name="workout-hours">
                      <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                        <span>19:00</span>
                      </div>
                    </label>
                  </div>
                  <div class="workout-hours-item">
                    <label>
                      <input type="radio" name="workout-hours">
                      <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                        <span>14:00</span>
                      </div>
                    </label>
                  </div>
                  <div class="workout-hours-item">
                    <label>
                      <input type="radio" name="workout-hours">
                      <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                        <span>15:00</span>
                      </div>
                    </label>
                  </div>
                  <div class="workout-hours-item">
                    <label>
                      <input type="radio" name="workout-hours">
                      <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                        <span>16:00</span>
                      </div>
                    </label>
                  </div>
                  <div class="workout-hours-item">
                    <label>
                      <input type="radio" name="workout-hours">
                      <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                        <span>17:00</span>
                      </div>
                    </label>
                  </div>
                  <div class="workout-hours-item">
                    <label>
                      <input type="radio" name="workout-hours">
                      <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                        <span>18:00</span>
                      </div>
                    </label>
                  </div>
                  <div class="workout-hours-item">
                    <label>
                      <input type="radio" name="workout-hours">
                      <div class="workout-hours-item-btn d-flex justify-content-center align-items-center">
                        <span>19:00</span>
                      </div>
                    </label>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chevronLeft from "@/components/svg/chevron-left.vue";
import bookmark from "@/components/svg/bookmark.vue";
import shareIcon from "@/components/svg/share-icon.vue";
import chevronDown from "@/components/svg/chevron-down.vue";

export default {
  name: "workout-detail",
  data () {
    return {
      calendarAttributes: [
        {
          dot: {
            style: {
              backgroundColor: '#a01e1d',
            }
          },
          dates: [
            new Date(2021, 0, 4), // Jan 4th
            new Date(2021, 0, 10), // Jan 10th
            new Date(2021, 0, 15), // Jan 15th
          ],
        },
        {
          dot: {
            style: {
              backgroundColor: '#777777',
            }
          },
          dates: [
            new Date(2021, 0, 4), // Jan 4th
            new Date(2021, 0, 10), // Jan 10th
            new Date(2021, 0, 15), // Jan 15th
          ],
        },
      ],
    }
  },
  components : {
    chevronLeft,
    bookmark,
    shareIcon,
    chevronDown,
  },
  methods: {
    toggleTextHeight() {
      let element = document.getElementsByClassName("workout-detail-text-description-wrapper")[0];

      if ( !element.classList.contains("open") ) {
        element.classList.add("open");
      }
    },
  },
}
</script>